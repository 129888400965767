import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogPostTemplate = ({ data: { previous, next, post } }) => {
  const featuredImage = {
    data: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: post.featuredImage?.node?.alt || ``,
  }
  // console.log('featuredImage',featuredImage);

  return (
    <Layout>
      <Seo title={post.title} description={post.excerpt} />
      <section className="max-w-screen-lg m-auto  mt-20 mb-4 px-5 py-1 md:p-0">
        <h1 className="text-center p-3  mb-4 text-2xl font-bold text-white font-family-opensans ">
          {parse(post.title)}
        </h1>

        <article
          className="blog-post max-w-screen-lg m-auto  mx-2 md:mx-0"
          itemScope
          itemType="http://schema.org/Article"
        >
          <div className="max-w-2xl mx-auto overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
            <div className="object-cover w-full h-64">
              {featuredImage?.data && (
                <GatsbyImage
                  image={featuredImage.data}
                  alt={featuredImage.alt}
                  className="object-cover w-full h-48 mb-5"
                />
              )}
            </div>
            <div className="p-6">
              <div>
                {!!post.category && (
                  <span className="text-xs font-medium text-blue-600 uppercase dark:text-blue-400">
                    {parse(post.category)}
                  </span>
                )}
                <div className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                  {!!post.content && (
                    <section itemProp="articleBody font-family-opensans">
                      {parse(post.content)}
                    </section>
                  )}
                </div>
                {featuredImage?.data && (
                  <GatsbyImage
                    image={featuredImage.data}
                    alt={featuredImage.alt}
                    className="w-full my-5"
                  />
                )}
              </div>

              <div className="mt-4">
                <div className="flex items-center">
                  <div className="flex items-center">
                    <div className="object-cover h-10 rounded-full">
                      {/* <GatsbyImage
              image={featuredImage.data}
              alt={featuredImage.alt}
              style={{ marginBottom: 50 }}
            /> */}
                    </div>
                    {!!post.author && (
                      <a
                        href="#"
                        className="mx-2 font-semibold text-gray-700 dark:text-gray-200"
                      >
                        {parse(post.author)}
                      </a>
                    )}
                  </div>
                  {!!post.date && (
                    <span className="mx-1 text-xs text-gray-600 dark:text-gray-300">
                      {parse(post.date)}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="my-4 p-3 flex items-stretch justify-items-stretch max-w-2xl mx-auto overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
            <div className="flex items-center px-4 py-2 mx-1 text-gray-700 transition-colors duration-200 transform bg-white rounded-md dark:bg-gray-900 dark:text-gray-200 hover:bg-blue-600 dark:hover:bg-blue-500 hover:text-white dark:hover:text-gray-200">
              {previous && (
                <Link to={previous.uri} rel="prev">
                  ← {parse(previous.title)}
                </Link>
              )}
            </div>

            <div className="flex items-center px-4 py-2 mx-1 text-gray-700 transition-colors duration-200 transform bg-white rounded-md dark:bg-gray-900 dark:text-gray-200 hover:bg-blue-600 dark:hover:bg-blue-500 hover:text-white dark:hover:text-gray-200">
              {next && (
                <Link to={next.uri} rel="next">
                  {parse(next.title)} →
                </Link>
              )}
            </div>
          </div>
        </article>
      </section>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
